import React from 'react'
import PropTypes from 'prop-types'
import Countdown from 'react-countdown-now'

const Completed = () => (
  <>
    <img src="https://i.imgur.com/mySurAx.png" alt="geh" />
    <p>Geh loaded</p>
  </>
)

const Renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <Completed />
  }

  return (
    <>
      <h1>Waiting for</h1>
      <img
        src="https://files.gamebanana.com/img/ico/sprays/5ab3ca41b7d9f.gif"
        alt=""
      />
      <h3>Mon, Jun 03 2019, 10:00</h3>
      <p>
        {days} days {hours} hours {minutes} minutes {seconds} seconds
      </p>
    </>
  )
}
const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="content">
      <div className="inner">
        <Countdown
          date={'Mon, 03 Jun 2019 10:00:00'}
          renderer={Renderer}
          onComplete={Completed}
        />
      </div>
    </div>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
